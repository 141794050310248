import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ExportDataV2 } from 'src/app/shared/models/exports/exports-v2.model';
import {
  ExportProductsBody,
  ExportProductsParams,
  ExportPromotionProductsBody,
  ExportPromotionProductsParams,
} from 'src/app/shared/models/product/product.model';

import { WebshopProduct } from 'src/app/shared/models/products/v3/products.model';

export class InitializeProductsV2State {
  static readonly type = '[Products V2] Initialize';
}

export class LoadProductsV2 {
  static readonly type = '[Products V2] Load Products V2';
}

export class ReloadDatatable {
  static readonly type = '[Products V2] Reload';
}

export class LoadProductsV2Count {
  static readonly type = '[Products V2] Load Products V2 Count';
}

export class AddSearchParam {
  static readonly type = '[Products V2] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Products V2] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Products V2] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Products V2] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Products V2] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Products V2] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Products V2] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Products V2] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Products V2] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Products V2] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Products V2] Toggle Filter';
}

export class ToggleRowSelection {
  static readonly type = '[Products V2] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public isSelected: boolean,
    public product: WebshopProduct
  ) {}
}

export class ClearSelection {
  static readonly type = '[Products V2] Clear Selection';
}

export class MasterToggleSelection {
  static readonly type = '[Products V2] Master Toggle Selection';
}

export class PhaseOutProduct {
  static readonly type = '[Products V2] Phase Out Product';

  constructor(
    public payload: {
      productUuid: string;
      resumingPurchase: Date | null;
      dialogRef: any;
    }
  ) {}
}

export class PhaseOutProducts {
  static readonly type = '[Products V2] Phase Out Products';

  constructor(
    public payload: {
      resumingPurchase: Date | null;
      dialogRef: any;
    }
  ) {}
}

export class DoNotPhaseOutProduct {
  static readonly type = '[Products V2] Do Not Phase Out Product';

  constructor(public productUuid: string) {}
}

export class DoNotPhaseOutProducts {
  static readonly type = '[Products V2] Do Not Phase Out Products';
}

export class IgnoreProduct {
  static readonly type = '[Products V2] Ignore Product';

  constructor(public productUuid: string) {}
}

export class IgnoreProducts {
  static readonly type = '[Products V2] Ignore Products';
}

export class UnignoreProduct {
  static readonly type = '[Products V2] Unignore Product';

  constructor(public productUuid: string) {}
}

export class UnignoreProducts {
  static readonly type = '[Products V2] Unignore Products';
}

export class RemoveAddedToPromotionProductsFromSelection {
  static readonly type =
    '[Products V2] Remove Added To Promotion Products From Selection';
}

export class ExportProductsV2 {
  static readonly type = '[Products V2] Export';

  constructor(public exportData: ExportDataV2) {}
}

export class AddFilterParamNoReload {
  static readonly type = '[Products V2] Add Filter Param No Reload';

  constructor(
    public param: ColumnFilterParam[],
    public reload?: boolean
  ) {}
}

export class ExportProducts {
  static readonly type = '[Products] Export';

  constructor(
    public webshopUUID: string,
    public params: ExportProductsParams,
    public settings: ExportProductsBody
  ) {}
}

export class ExportPromotionProducts {
  static readonly type = '[Promotion Products] Export';

  constructor(
    public webshopUUID: string,
    public promotionUUID: string,
    public params: ExportPromotionProductsParams,
    public settings: ExportPromotionProductsBody
  ) {}
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Products V2] Reset Pagination And Load Data';
}
